const INITIAL_STATE = {
  sections: [
    {
      title: "menu.women_perfumes",
      imageUrl: "/img/menu/men-perfumes.jpg",
      id: 4,
      linkUrl: "shop/womens",
    },
    {
      title: "menu.men_perfumes",
      imageUrl: "/img/menu/women-perfumes.jpg",
      id: 5,
      linkUrl: "shop/mens",
    },
    {
      title: "menu.al_rehab",
      imageUrl: "/img/menu/al-rehab.jpeg",
      id: 3,
      linkUrl: "shop/al_rehab",
    },
    {
      title: "menu.care_products",
      imageUrl: "/img/menu/care-products.jpg",
      size: "large",
      id: 1,
      linkUrl: "shop/oils",
      // firstItem: 'Oils',
      // secondItem: 'Skin Products',
      // thirdItem: 'Hair Products'
    },
    {
      title: "menu.clothes_books",
      imageUrl: "/img/menu/clothing.jpg",
      size: "large",
      id: 2,
      linkUrl: "shop/clothes_books",
    },
  ],
};

const directoryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default directoryReducer;
