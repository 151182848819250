import "./social-media.styles.scss";

import { BsInstagram } from "react-icons/bs";
import { TiSocialFacebookCircular } from "react-icons/ti";
import { SiGooglemaps } from "react-icons/si";

const SocialMedia = () => (
  <div className="sm-container">
    <div className="sm-box">
      <a
        href="https://www.instagram.com/mekkahorient/?hl=en"
        target="_blank"
        rel="noopener noreferrer"
        className="option"
      >
        <BsInstagram id="ig-icon" />
      </a>
    </div>

    <div className="sm-box">
      <a
        href="https://www.facebook.com/pages/category/Beauty--cosmetic---personal-care/Mekkah-Orient-2084857684916947/"
        target="_blank"
        rel="noopener noreferrer"
        className="option"
      >
        <TiSocialFacebookCircular id="fb-icon" />
      </a>
    </div>

    <div className="sm-box">
      <a
        href="https://www.google.com/maps/place/Mekkah+Orient+Perfumery/@42.6615303,20.2929044,19.5z/data=!4m13!1m7!3m6!1s0x1352fdc097e0cba1:0x2d9296d8cc0eba51!2sPeja!3b1!8m2!3d42.6592868!4d20.2887358!3m4!1s0x1352fdb10548fcfb:0x4c323189a37cd293!8m2!3d42.6614845!4d20.2927378"
        target="_blank"
        rel="noopener noreferrer"
        className="option"
      >
        <SiGooglemaps id="map-icon" />
      </a>
    </div>
  </div>
);

export default SocialMedia;
