import "./modal.styles.scss";
import logo2 from "../../assets/logo2.png";

const Modal = ({ children, onClick, closeModal }) => (
  <div className="modal-container" onClick={onClick}>
    <div className="modal-header">
      <img src={logo2} alt="Logo" />
      <span className="close-btn" onClick={closeModal}>
        &times;
      </span>
      {/* <p className="title">Ju lutem shenoni te dhenat e nevojshme 
      Do te pranoni nje thirrje per konfirmim te porosise</p> */}
    </div>
    <div className="modal-body">{children}</div>
  </div>
);

export default Modal;
