import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import CheckoutItem from "../../components/checkout-item/checkout-item";
import StripeCheckoutButton from "../../components/stripe-button/stripe-button";

import FormInput from "../../components/form-input/form-input";
import CustomButton from "../../components/custom-button/custom-button";

import Backdrop from "../../components/backdrop";
import Modal from "../../components/modal";

import {
  selectCartItems,
  selectCartTotal,
} from "../../redux/cart/cart.selectors";

import "./checkout.styles.scss";

import { useTranslation } from "react-i18next";

import emailjs from "@emailjs/browser";

import Alert from "../../components/alert/alert.js";

const initialInputs = {
  name: "",
  address: "",
  city: "",
  phone: "",
  notes: "",
};

const CheckoutPage = ({ cartItems, total }) => {
  const [showModal, setShowModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const [inputs, setInputs] = useState(initialInputs);
  const { t, i18n } = useTranslation();

  const form = useRef();

  const sendEmail = (e) => {
    e.stopPropagation();
    e.preventDefault();

    console.log(form.current);

    emailjs
      .sendForm(
        "service_qrs6nso",
        "template_mznwwma",
        form.current,
        "4Kz_N4slmNQEGeE_3"
      )
      .then(
        (result) => {
          console.log(result.text);
          setInputs(initialInputs);
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
          }, 3000);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  console.log(window.location.origin);
  console.log("cartItems", cartItems);



  return (
    <div className="checkout-page">
      <div className="checkout-header">
        <div className="header-block">{t("checkout_menu.product")}</div>
        <div className="header-block">{t("checkout_menu.description")}</div>
        <div className="header-block">{t("checkout_menu.quantity")}</div>
        <div className="header-block">{t("checkout_menu.price")}</div>
        <div className="header-block">{t("checkout_menu.remove")}</div>
      </div>
      {cartItems.map((cartItem) => (
        <CheckoutItem key={cartItem.id} cartItem={cartItem} />
      ))}
      <div className="total">
        {t("checkout_menu.total")} €{total}
      </div>
      <Backdrop visible={showModal} onClick={() => setShowModal(false)}>
        <Modal
          visible={showModal}
          onClick={(e) => {
            e.stopPropagation();
          }}
          closeModal={() => setShowModal(false)}
        >
          <form ref={form} onSubmit={sendEmail}>
            <FormInput
              type="text"
              name="name"
              value={inputs.name}
              onChange={handleChange}
              label={t("form.username")}
              required
            />
            <FormInput
              type="text"
              name="address"
              value={inputs.address}
              onChange={handleChange}
              label={t("form.address")}
              required
            />
            <FormInput
              type="text"
              name="city"
              value={inputs.city}
              onChange={handleChange}
              label={t("form.city")}
              required
            />
            <FormInput
              type="phone"
              name="phone"
              value={inputs.phone}
              onChange={handleChange}
              label={t("form.telephone")}
              required
            />
            <FormInput
              type="text"
              name="notes"
              value={inputs.notes}
              onChange={handleChange}
              label={t("form.notes_about_order")}
            />
            {cartItems.map((item) => (
              <>
                <textarea style={{display: "none"}}
                  name="message"
                  value={`Sasia: ${item.quantity} - Produkti: ${t(item.name)} - Çmimi: ${item.price} euro - Imazhi: ${window.location.origin + item.imageUrl} | `}
                />
              </>
            ))}
            <CustomButton type="submit" value="Send">
              {t("form.order_button")}
            </CustomButton>
          </form>
          {showAlert ? <Alert /> : null}
        </Modal>
      </Backdrop>
      <StripeCheckoutButton price={total} onClick={() => setShowModal(true)} />
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  cartItems: selectCartItems,
  total: selectCartTotal,
});

export default connect(mapStateToProps)(CheckoutPage);
