import React from "react";
import "./menubar.styles.scss";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function Menubar() {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <nav className="navbar navbar-expand-lg ">
        <div className="container-fluid">
          <div className="navmenu">
            <Link className="navbar-brand" to="oils">
              {t("submenu.oils")}
            </Link>
            <Link className="navbar-brand" to="skinproducts">
              {t("submenu.skin_products")}
            </Link>
            <Link className="navbar-brand" to="hairproducts">
              {t("submenu.hair_products")}
            </Link>
            <Link className="navbar-brand" to="healthyproducts">
              {t("submenu.healthy_products")}
            </Link>
          </div>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDarkDropdown"
            aria-controls="navbarNavDarkDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarNavDarkDropdown">
            <ul className="navbar-nav">
              <li className="nav-item dropdown">
                <Link className="dropdown-item" to="oils">
                  {t("submenu.oils")}
                </Link>
              </li>
              <li>
                <Link class="dropdown-item" to="skinproducts">
                  {t("submenu.skin_products")}
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="hairproducts">
                  {t("submenu.hair_products")}
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="healthyproducts">
                  {t("submenu.healthy_products")}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
export default Menubar;
