import "./footer.style.scss";
import Logo from "../../assets/logo2.png";
import { Link } from "react-router-dom";

const Footer = () => {
  console.log(window.location.pathname);
  return (
    <div className="footer">
      <h1 className="footer-copyright">Copyright © 2023 mekkahorient</h1>
    </div>
  );
};

export default Footer;
