import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

// import { auth } from "../../firebase/firebase.utils";
import CartIcon from "../cart-icon/cart-icon";
import CartDropdown from "../cart-dropdown/cart-dropdown";
import { selectCartHidden } from "../../redux/cart/cart.selectors";
import { selectCurrentUser } from "../../redux/user/user.selectors";
// import { ReactComponent as Logo } from '../../assets/crown.svg';
import Logo from "../../assets/logo2.png";
import "./header.styles.scss";
import { BiWorld } from "react-icons/bi";

import { useTranslation } from "react-i18next";

const Header = ({ currentUser, hidden }) => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = (value) => () => {
    setSelectedOption(value);
    setIsOpen(false);
    console.log(selectedOption);
  };

  function TranslateClick(lang) {
    i18n.changeLanguage(lang);
  }
  return (
    <div className="header">
      <Link className="logo-container" to="/">
        {/* <Logo className='logo' /> */}
        <img src={Logo} alt="Logo" className="logo" />
      </Link>
      <div className="options">
        {/* <Link className='option' to='/shop'>
        SHOP
      </Link> */}
        {/* 
        <a
          href="https://www.instagram.com/mekkahorient/?hl=en"
          target="_blank"
          rel="noopener noreferrer"
          className="option"
        >
          <BsInstagram
            style={{ width: "25px", height: "30px", color: "#C2AB72" }}
          />
        </a>

        <a
          href="https://www.facebook.com/pages/category/Beauty--cosmetic---personal-care/Mekkah-Orient-2084857684916947/"
          target="_blank"
          rel="noopener noreferrer"
          className="option"
        >
          <TiSocialFacebookCircular
            style={{ width: "35px", height: "35px", color: "#C2AB72" }}
          />
        </a>

        <a
          href="https://www.google.com/maps/place/Mekkah+Orient+Perfumery/@42.6615303,20.2929044,19.5z/data=!4m13!1m7!3m6!1s0x1352fdc097e0cba1:0x2d9296d8cc0eba51!2sPeja!3b1!8m2!3d42.6592868!4d20.2887358!3m4!1s0x1352fdb10548fcfb:0x4c323189a37cd293!8m2!3d42.6614845!4d20.2927378"
          target="_blank"
          rel="noopener noreferrer"
          className="option"
        >
          <SiGooglemaps
            style={{ width: "25px", height: "30px", color: "#C2AB72" }}
          />
        </a> */}


        {/* {currentUser ? (
        <div className='option' onClick={() => auth.signOut()}>
          SIGN OUT
        </div>
      ) : (
        <Link className='option' to='/signin'>
          SIGN IN
        </Link>
      )} */}
      </div>
      <CartIcon />

      {hidden ? null : <CartDropdown />}
      <div className="dropdown-container">
        
        <div className="dropdown-header" onClick={toggling}>
          <BiWorld className="translate-icon" size={30} />
          {/* {selectedOption} */}
        </div>

        {isOpen && (
          <div className="dropdown-list-container">
            <div className="dropdown-list">
              <div className="list-item" onClick={onOptionClicked("Shqip")}>
                <button onClick={() => TranslateClick("al")}>
                  <img src="/img/ks-flag.png" />
                </button>
              </div>
              <div className="list-item" onClick={onOptionClicked("English")}>
                <button onClick={() => TranslateClick("en")}>
                  <img src="/img/en-flag.png" />
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  hidden: selectCartHidden,
});

export default connect(mapStateToProps)(Header);
