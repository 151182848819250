import React from "react";
import { connect } from "react-redux";

import CustomButton from "../custom-button/custom-button";
import { addItem } from "../../redux/cart/cart.actions.js";

import "./collection-item.styles.scss";

import { useTranslation } from "react-i18next";

const CollectionItem = ({ item, addItem }) => {
  const { name, price, imageUrl } = item;
  const { t, i18n } = useTranslation();

  return (
    <div className="collection-item">
      <div
        className="image"
        style={{
          backgroundImage: `url(${imageUrl})`,
        }}
      />
      {/* <img src={imageUrl} className="image"/> */}
      <div className="collection-footer">
        <p className="name">{t(name)}</p>
        <p className="price">€{price.toFixed(2)}</p>
      </div>
      <CustomButton onClick={() => addItem(item)} className="custom-button" inverted>
        {t("products.add_to_cart")}
      </CustomButton>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  addItem: (item) => dispatch(addItem(item)),
});

export default connect(null, mapDispatchToProps)(CollectionItem);
