import React from "react";
import "./alert.styles.scss";

import { useTranslation } from "react-i18next";

const Alert = () => {
  const { t, i18n } = useTranslation();
  return <div className="container">{t("success_message")}</div>;
};

export default Alert;
