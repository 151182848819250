import React from "react";
import StripeCheckout from "react-stripe-checkout";
import CustomButton from "../custom-button/custom-button";

import { useTranslation } from "react-i18next";

const StripeCheckoutButton = ({ price, onClick }) => {
  const { t, i18n } = useTranslation();
  function TranslateClick(lang) {
    i18n.changeLanguage(lang);
  }
  // const priceForStripe = price * 100;
  // const publishableKey =
  //   "pk_test_51Hap51DBZiDUCHzd9Jg7LxIj8045yt54bg6seWjTDTP1l3qsNjDQxgYRK8aduq0zA1YrqZo3Y58DYm0JZC7LEXcr00tUpJGdT0";

  // const onToken = (token) => {
  //   console.log(token);
  //   alert("Payment Succesful!");
  // };

  return (
    // <StripeCheckout

    //   label='Ship Now'
    //   name='Mekkah Orient Shop'
    //   billingAddress
    //   shippingAddress
    //   image='https://svgshare.com/i/CUz.svg'
    //   description={`Your total is $${price}`}
    //   amount={priceForStripe}
    //   panelLabel='Pay Now'
    //   token={onToken}
    //   stripeKey={publishableKey}

    // />
    <a href="#" onClick={onClick}>
      <CustomButton>
        {t("checkout.processing")}
      </CustomButton>
    </a>
  );
};

export default StripeCheckoutButton;
