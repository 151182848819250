import React from "react";
import { Route, Switch } from "react-router-dom";
import Menubar from "../collection/menubar.js";
import { useLocation } from "react-router-dom";

import CollectionsOverview from "../../components/collection-overview/collection-overview";
import CollectionPage from "../collection/collection";

const ShopPage = ({ match }) => {
  const location = useLocation();

  return (
    <div className="shop-page">
      {location.pathname === "/shop/clothes_books" ||
      location.pathname === "/shop/mens" ||
      location.pathname === "/shop/womens" ||
      location.pathname === "/shop/care_products" ||
      location.pathname === "/shop/al_rehab" ? null : (
        <Menubar />
      )}

      <Switch>
        <Route exact path={`${match.path}`} component={CollectionsOverview} />
        <Route
          path={`${match.path}/:collectionId`}
          component={CollectionPage}
        />
      </Switch>
    </div>
  );
};
export default ShopPage;
