const SHOP_DATA = {
  al_rehab: {
    id: 2,
    title: "",
    routeName: "al_rehab",
    items: [
      {
        id: 1,
        name: "Africana (6ml)",
        imageUrl: "/img/al-rehab/africana.jpeg",
        secondImageUrl: "/img/mens-perfumes/perfume.png",
        price: 6.0,
      },
      {
        id: 2,
        name: "Al Fares (6ml)",
        imageUrl: "/img/al-rehab/al-fares.jpeg",
        price: 6.0,
      },
      {
        id: 3,
        name: "Al Rehab Grapes (6ml)",
        imageUrl: "/img/al-rehab/al-rehab-grapes.jpeg",
        price: 6.0,
      },
      {
        id: 4,
        name: "Alf Zahra (6ml)",
        imageUrl: "/img/al-rehab/alf-zahra.jpeg",
        price: 6.0,
      },
      {
        id: 5,
        name: "Aroosah (6ml)",
        imageUrl: "/img/al-rehab/aroosah.jpeg",
        price: 6.0,
      },
      {
        id: 6,
        name: "Aseel (6ml)",
        imageUrl: "/img/al-rehab/aseel.jpeg",
        price: 6.0,
      },
      {
        id: 7,
        name: "Bakhour (6ml)",
        imageUrl: "/img/al-rehab/bakhour.jpeg",
        price: 6.0,
      },
      {
        id: 8,
        name: "Bilkis (6ml)",
        imageUrl: "/img/al-rehab/bilkis.jpeg",
        price: 6.0,
      },
      {
        id: 9,
        name: "Blank (6ml)",
        imageUrl: "/img/al-rehab/blank.jpeg",
        price: 6.0,
      },
      {
        id: 10,
        name: "Champion Black (6ml)",
        imageUrl: "/img/al-rehab/champion-black.jpeg",
        price: 6.0,
      },
      {
        id: 11,
        name: "Cherry Flower (6ml)",
        imageUrl: "/img/al-rehab/cherry-flower.jpeg",
        price: 6.0,
      },
      {
        id: 12,
        name: "Choco Musk (6ml)",
        imageUrl: "/img/al-rehab/choco-musk.jpeg",
        price: 6.0,
      },
      {
        id: 13,
        name: "Classic (6ml)",
        imageUrl: "/img/al-rehab/classic.jpeg",
        price: 6.0,
      },
      {
        id: 14,
        name: "Cobra (6ml)",
        imageUrl: "/img/al-rehab/cobra.jpeg",
        price: 6.0,
      },
      {
        id: 15,
        name: "Crystal (6ml)",
        imageUrl: "/img/al-rehab/crystal.jpeg",
        price: 6.0,
      },
      {
        id: 16,
        name: "Daimond (6ml)",
        imageUrl: "/img/al-rehab/daimond.jpeg",
        price: 6.0,
      },
      {
        id: 17,
        name: "Dakar (6ml)",
        imageUrl: "/img/al-rehab/dakar.jpeg",
        price: 6.0,
      },
      {
        id: 18,
        name: "Dalal (6ml)",
        imageUrl: "/img/al-rehab/dalal.jpeg",
        price: 6.0,
      },
      {
        id: 19,
        name: "Denh Al Oud (6ml)",
        imageUrl: "/img/al-rehab/denh-al-oud.jpeg",
        price: 6.0,
      },
      {
        id: 20,
        name: "Cobra (6ml)",
        imageUrl: "/img/al-rehab/cobra.jpeg",
        price: 6.0,
      },
      {
        id: 21,
        name: "Crystal (6ml)",
        imageUrl: "/img/al-rehab/crystal.jpeg",
        price: 6.0,
      },
      {
        id: 22,
        name: "For Men (6ml)",
        imageUrl: "/img/al-rehab/for-men.jpeg",
        price: 6.0,
      },
      {
        id: 23,
        name: "Fruit (6ml)",
        imageUrl: "/img/al-rehab/fruit.jpeg",
        price: 6.0,
      },
      {
        id: 24,
        name: "Full (6ml)",
        imageUrl: "/img/al-rehab/full.jpeg",
        price: 6.0,
      },
      {
        id: 25,
        name: "Golden (6ml)",
        imageUrl: "/img/al-rehab/golden.jpeg",
        price: 6.0,
      },
      {
        id: 26,
        name: "Green Tea (6ml)",
        imageUrl: "/img/al-rehab/green-tea.jpeg",
        price: 6.0,
      },
      {
        id: 27,
        name: "Jasmin (6ml)",
        imageUrl: "/img/al-rehab/jasmin.jpeg",
        price: 6.0,
      },
      {
        id: 28,
        name: "Karina Rose (6ml)",
        imageUrl: "/img/al-rehab/karina-rose.jpeg",
        price: 6.0,
      },
      {
        id: 29,
        name: "Kahliji (6ml)",
        imageUrl: "/img/al-rehab/khaliji.jpeg",
        price: 6.0,
      },
      {
        id: 30,
        name: "Lord (6ml)",
        imageUrl: "/img/al-rehab/lord.jpeg",
        price: 6.0,
      },
      {
        id: 31,
        name: "Lovely (6ml)",
        imageUrl: "/img/al-rehab/lovely.jpeg",
        price: 6.0,
      },
      {
        id: 32,
        name: "Mister (6ml)",
        imageUrl: "/img/al-rehab/mister.jpeg",
        price: 6.0,
      },
      {
        id: 33,
        name: "Makhalat Al Rehab (6ml)",
        imageUrl: "/img/al-rehab/makhalat-al-rehab.jpeg",
        price: 6.0,
      },
      {
        id: 34,
        name: "Makhalat Dubai (6ml)",
        imageUrl: "/img/al-rehab/makhalat-dubai.jpeg",
        price: 6.0,
      },
      {
        id: 35,
        name: "Musk al Madinah (6ml)",
        imageUrl: "/img/al-rehab/musk-al-madinah.jpeg",
        price: 6.0,
      },
      {
        id: 36,
        name: "Musk Rose(6ml)",
        imageUrl: "/img/al-rehab/musk-rose.jpeg",
        price: 6.0,
      },
      {
        id: 37,
        name: "Nebrad (6ml)",
        imageUrl: "/img/al-rehab/nebras.jpeg",
        price: 6.0,
      },
      {
        id: 38,
        name: "Original (6ml)",
        imageUrl: "/img/al-rehab/original.jpeg",
        price: 6.0,
      },
      {
        id: 39,
        name: "Randa (6ml)",
        imageUrl: "/img/al-rehab/randa.jpeg",
        price: 6.0,
      },
      {
        id: 40,
        name: "Rasha (6ml)",
        imageUrl: "/img/al-rehab/rasha.jpeg",
        price: 6.0,
      },
      {
        id: 41,
        name: "Red Rose (6ml)",
        imageUrl: "/img/al-rehab/red-rose.jpeg",
        price: 6.0,
      },
      {
        id: 42,
        name: "Rihanat al Rehab (6ml)",
        imageUrl: "/img/al-rehab/rihanat-al-rehab.jpeg",
        price: 6.0,
      },
      {
        id: 43,
        name: "Rose (6ml)",
        imageUrl: "/img/al-rehab/rose.jpeg",
        price: 6.0,
      },
      {
        id: 44,
        name: "Saat Safa (6ml)",
        imageUrl: "/img/al-rehab/saat-safa.jpeg",
        price: 6.0,
      },
      {
        id: 45,
        name: "Sandra (6ml)",
        imageUrl: "/img/al-rehab/sandra.jpeg",
        price: 6.0,
      },
      {
        id: 46,
        name: "Secret Lady (6ml)",
        imageUrl: "/img/al-rehab/secret-lady.jpeg",
        price: 6.0,
      },
      {
        id: 47,
        name: "Secret Man (6ml)",
        imageUrl: "/img/al-rehab/secret-man.jpeg",
        price: 6.0,
      },
      {
        id: 48,
        name: "Shadha (6ml)",
        imageUrl: "/img/al-rehab/shadha.jpeg",
        price: 6.0,
      },
      {
        id: 49,
        name: "Shaikhah (6ml)",
        imageUrl: "/img/al-rehab/shaikhah.jpeg",
        price: 6.0,
      },
      {
        id: 50,
        name: "Silver (6ml)",
        imageUrl: "/img/al-rehab/silver.jpeg",
        price: 6.0,
      },
      {
        id: 51,
        name: "Soft (6ml)",
        imageUrl: "/img/al-rehab/soft.jpeg",
        price: 6.0,
      },
      {
        id: 52,
        name: "Sulltan (6ml)",
        imageUrl: "/img/al-rehab/sultan.jpeg",
        price: 6.0,
      },
      {
        id: 53,
        name: "Super Man (6ml)",
        imageUrl: "/img/al-rehab/super-man.jpeg",
        price: 6.0,
      },
      {
        id: 54,
        name: "Susan (6ml)",
        imageUrl: "/img/al-rehab/susan.jpeg",
        price: 6.0,
      },
      {
        id: 55,
        name: "Taif Rose (6ml)",
        imageUrl: "/img/al-rehab/taif-rose.jpeg",
        price: 6.0,
      },
      {
        id: 56,
        name: "Tooty Musk (6ml)",
        imageUrl: "/img/al-rehab/tooty-musk.jpeg",
        price: 6.0,
      },
      {
        id: 57,
        name: "White Musk (6ml)",
        imageUrl: "/img/al-rehab/white-musk.jpeg",
        price: 6.0,
      },
      {
        id: 58,
        name: "Zahrat Hawaii (6ml)",
        imageUrl: "/img/al-rehab/zahrat-hawaii.jpeg",
        price: 6.0,
      },
    ],
  },
  clothes_books: {
    id: 3,
    title: "",
    routeName: "clothes_books",
    items: [
      {
        id: 1,
        name: "products.clothes_books.termal_mest",
        imageUrl: "/img/clothes/termal_mest.jpeg",
        price: 10,
      },
      {
        id: 2,
        name: "products.clothes_books.two_parts_hijab",
        imageUrl: "/img/clothes/hijab.jpeg",
        price: 15,
      },
      {
        id: 3,
        name: "products.clothes_books.rainbow_quran",
        imageUrl: "/img/books/rainbow-quran.jpg",
        price: 22,
      },
      // {
      //   id: 4,
      //   name: "products.clothes.men_skull_cap", . .  .
      //   imageUrl: "/img/clothes/men_skull_cap.png",
      //   price: 5,
      // },
      // {
      //   id: 6,
      //   name: "products.clothes.prayer_mat",
      //   imageUrl: "/img/clothes/prayer_mat.png",
      //   price: 22,
      // }
    ],
  },
  womens: {
    id: 4,
    title: "",
    routeName: "womens",
    items: [
      {
        id: 1,
        name: "Armani Diamonds (6ml)",
        imageUrl: "/img/women-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 2,
        name: "Armani Si (6ml)",
        imageUrl: "/img/women-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 3,
        name: "Aquolina Pink Sugar (6ml)",
        imageUrl: "/img/women-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 39,
        name: "Baccarat Rouge 540 (6ml)",
        imageUrl: "/img/women-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 4,
        name: "Burberry Weekend (6ml)",
        imageUrl: "/img/women-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 5,
        name: "Britney Spears Midnight Fantasy (6ml)",
        imageUrl: "/img/women-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 6,
        name: "Carolina Herrera Good Girl (6ml)",
        imageUrl: "/img/women-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 7,
        name: "Carolina Herrera Vip 212 Rose(6ml)",
        imageUrl: "/img/women-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 8,
        name: "Coco Chanel Mademoisele (6ml)",
        imageUrl: "/img/women-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 9,
        name: "Calvin Klein Euphoria (6ml)",
        imageUrl: "/img/women-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 10,
        name: "Cacharel Amor Amor(6ml)",
        imageUrl: "/img/women-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 11,
        name: "Dior Hypnotic Poison (6ml)",
        imageUrl: "/img/women-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 12,
        name: "Dior J'adore (6ml)",
        imageUrl: "/img/women-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 13,
        name: "Davidoff Cool Water (6ml)",
        imageUrl: "/img/women-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 14,
        name: "Escada Moon Sparkle (6ml)",
        imageUrl: "/img/women-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 15,
        name: "Escada Rockin'Rio (6ml)",
        imageUrl: "/img/women-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 16,
        name: "Escada Born in Paradise (6ml)",
        imageUrl: "/img/women-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 17,
        name: "Escada Cherry in The Air (6ml)",
        imageUrl: "/img/women-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 18,
        name: "Escada Turquoise Summer (6ml)",
        imageUrl: "/img/women-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 19,
        name: "Escada Flor Del Sol (6ml)",
        imageUrl: "/img/women-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 20,
        name: "Elie Saab Girl Of Now (6ml)",
        imageUrl: "/img/women-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 21,
        name: "Esika Vanilla (6ml)",
        imageUrl: "/img/women-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 40,
        name: "Gucci Guilty (6ml)",
        imageUrl: "/img/women-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 41,
        name: "Gucci Bloom (6ml)",
        imageUrl: "/img/women-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 42,
        name: "Giorgio Armani My Way (6ml)",
        imageUrl: "/img/women-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 43,
        name: "Hugo Boss Woman (6ml)",
        imageUrl: "/img/women-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 22,
        name: "Lacoste Pink (6ml)",
        imageUrl: "/img/women-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 23,
        name: "Lancome La Vie Est Belle (6ml)",
        imageUrl: "/img/women-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 24,
        name: "Lancome Tresore Midnight Rose (6ml)",
        imageUrl: "/img/women-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 25,
        name: "Lancome Trésor La Nuit (6ml)",
        imageUrl: "/img/women-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 108,
        name: "Lattafa Oud Mood (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 26,
        name: "Nina Ricci (6ml)",
        imageUrl: "/img/women-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 27,
        name: "Naomi Campbell (6ml)",
        imageUrl: "/img/women-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 28,
        name: "Paco Rabanne Lady Million (6ml)",
        imageUrl: "/img/women-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 29,
        name: "Paco Rabanne Olympea (6ml)",
        imageUrl: "/img/women-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 30,
        name: "Paco Rabanne Lady Million Prive (6ml)",
        imageUrl: "/img/women-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 31,
        name: "Paco Rabanne Pure XS For Her (6ml)",
        imageUrl: "/img/women-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 32,
        name: "Victoria's Secret Bombshell (6ml)",
        imageUrl: "/img/women-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 33,
        name: "Victoria Secret Very Sexy (6ml)",
        imageUrl: "/img/women-perfumes/wp.jpg",
        price: 6.0,
      }, 
      {
        id: 34,
        name: "Versace Diamond (6ml)",
        imageUrl: "/img/women-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 35,
        name: "Versace Yellow Diamond (6ml)",
        imageUrl: "/img/women-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 36,
        name: "Versace Bright Crystal (6ml)",
        imageUrl: "/img/women-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 37,
        name: "Versace Crystal Noir (6ml)",
        imageUrl: "/img/women-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 38,
        name: "Yves Saint Laurent Libre (6ml)",
        imageUrl: "/img/women-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 38,
        name: "Yves Saint Black Opium (6ml)",
        imageUrl: "/img/women-perfumes/wp.jpg",
        price: 6.0,
      },
    ],
  },
  mens: {
    id: 5,
    title: "",
    routeName: "mens",
    items: [
      {
        id: 1,
        name: "Armani Stronger With You (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 2,
        name: "Armani Stronger With You Only (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 3,
        name: "Armani Stronger With You Leather (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 4,
        name: "Armani Aqua Di Gio (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 5,
        name: "Armani Aqua Di Gio Absulo (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 6,
        name: "Armani Aqua Di Gio Profumo (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 7,
        name: "Armani Aqua Di Gio Porfondo (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 8,
        name: "Armani Black Code (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 9,
        name: "Armani Code Absulo (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 10,
        name: "Armani Code Profumo (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 11,
        name: "Azzaro The Most Wanted (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 12,
        name: "Azzaro Sport (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 13,
        name: "Azzaro Chrome (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 14,
        name: "Azzaro Wanted (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 15,
        name: "Azzaro Twin (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 16,
        name: "Arabian Oud Tahara Musk (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 17,
        name: "Baccarat Rouge 540 (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 18,
        name: "Bvlgari Wood Essence (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 19,
        name: "Bvlgari Man in Black (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 20,
        name: "Bvlgari Aqva (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 21,
        name: "Chanel Bleu de Chanel (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 22,
        name: "Chanel Allure Homme Sport (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 23,
        name: "Carolina Herrera Bad Boy (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 24,
        name: "Carolina Herrera 212 Vip (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 25,
        name: "Carolina Herrera 212 Black (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 26,
        name: "Carolina Herrera 212 Black Extra (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 27,
        name: "Carolina Herrera 212 Heroes (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 28,
        name: "Carolina Herrera 212 (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 29,
        name: "Creed Aventus (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 30,
        name: "Creed Royal Oud (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 31,
        name: "Dolce Gabanna The One (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 32,
        name: "Dolce Gabanna Light Blue (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 33,
        name: "Dolce Gabanna Velvet Oriental Musk (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 34,
        name: "Dolce Gabanna King (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 35,
        name: "Dolce Gabanna Pour Homme (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 36,
        name: "Dior Sauvage (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 37,
        name: "Dior Fahrenheit (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 38,
        name: "Dior Homme (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 39,
        name: "Dior Homme Intense (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 40,
        name: "Dunhill London (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 41,
        name: "Dunhill Desire (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 42,
        name: "Diesel Tatto (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 43,
        name: "Diesel Only The Brave (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 43,
        name: "Diesel Bad (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 44,
        name: "Davidoff Zino (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 45,
        name: "Gucci Guilty (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 46,
        name: "Gucci Guilty Absolute(6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 47,
        name: "Gucci Oud(6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 48,
        name: "Givenchy Gentleman (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 49,
        name: "Givenchy Gentleman Reserve Privèe (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 82,
        name: "Givenchy Pi(6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 50,
        name: "Gianfranco Ferre For Man (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 51,
        name: "Hugo Boss Orange (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 52,
        name: "Hugo Boss Bottled (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 53,
        name: "Hugo Boss Bottled Night (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 54,
        name: "Hugo Boss The Scent (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 55,
        name: "Hugo Boss Selection (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 56,
        name: "Joop Homme (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 57,
        name: "Joop Night (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 58,
        name: "Jean Paul Gaultier (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 59,
        name: "JPG Scandal Pour Homme(6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 60,
        name: "JPG Ultra Male (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 61,
        name: "JPG La Male (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 62,
        name: "Kilian Angel's Share (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 100,
        name: "Karamat White Oud K (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 63,
        name: "Lattafa Oud Mood (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 64,
        name: "Laura Splendid(6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 65,
        name: "Laura Biagiotti Roma Uomo (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 66,
        name: "Lacoste Black (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 67,
        name: "Lacoste White (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 68,
        name: "Lacoste Yellow (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 69,
        name: "Lacoste Essential (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 70,
        name: "Lacoste Challenge (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 71,
        name: "Louis Vuitton Ombre Nomade (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 72,
        name: "Mancera Red Tobacco (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 73,
        name: "Mont Blanc Explorer (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 74,
        name: "Mont Blanc Legend Night (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 75,
        name: "Mont Blanc Legend Red (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 76,
        name: "Montale Paris Intense Cafe (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 77,
        name: "Montale Paris Arabians Tonka (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 78,
        name: "Mugler A-Men (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 80,
        name: "Nautica Voyage (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 81,
        name: "Nikos Sculpture Homme (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 83,
        name: "Paco Rabanne 1 Million (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 84,
        name: "Paco Rabanne 1 Million Elixir (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 85,
        name: "Paco Rabanne 1 Million Lucky (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 86,
        name: "Paco Rabanne 1 Million Prive (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 87,
        name: "Paco Rabanne 1 Million Black XS L'EXCSES (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 88,
        name: "Paco Rabanne 1 Million Phantom (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 89,
        name: "Paco Rabanne 1 Million Invictus (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 90,
        name: "Paco Rabanne 1 Million Invictus Legend (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 91,
        name: "Paco Rabanne 1 Million Invictus Victory (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 92,
        name: "Paco Rabanne 1 Million Invictus Onyx(6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 93,
        name: "Paco Rabanne 1 Million Pour Homme(6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 94,
        name: "Parfums De Marly Layton (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 95,
        name: "Parfums De Marly Pegasus (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 96,
        name: "Prada L'Homme (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 97,
        name: "Rasasi Al Wisam Day (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 98,
        name: "Roberto Cavalli Just Cavalli (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 99,
        name: "Roja Amber Aoud (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 101,
        name: "Tom Ford Lost Cherry (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 102,
        name: "Tom Ford Black Orchid (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 103,
        name: "Tom Ford Tobacco Vanille (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 104,
        name: "Tom Ford Oud Wood (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 105,
        name: "Tom Ford F.Fabulous (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 106,
        name: "Tom Ford Noir Extreme (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 107,
        name: "Tom Ford Soleil Blanc (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 101,
        name: "Tom Ford Lost Cherry (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 109,
        name: "The Body Shop Red Musk (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 110,
        name: "Tiziana Terenzi Kirké (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 111,
        name: "Victor&Rolf Spice Bomb (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 112,
        name: "Versace Eros (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 113,
        name: "Versace Dylan Blue (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 114,
        name: "Versace Pour Homme (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 115,
        name: "Versace Blue Jeans (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 116,
        name: "Valentino Born in Roma Fantasy (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 117,
        name: "Valentino Uomo (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 118,
        name: "Xerjoff Alexadria II (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 119,
        name: "Xerjoff Erba Pura (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 120,
        name: "Xerjoff Naxos (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 121,
        name: "YSL (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 122,
        name: "YSL Electrique (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 123,
        name: "YSL L'Homme (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 124,
        name: "YSL L'Homme Ultime (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 125,
        name: "Zara Gold (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
      {
        id: 125,
        name: "Zara Tobacco Collection (6ml)",
        imageUrl: "/img/mens-perfumes/wp.jpg",
        price: 6.0,
      },
    ],
  },
  oils: {
    id: 6,
    title: "",
    routeName: "oils",
    items: [
      {
        id: 1,
        name: "products.care_products.oils.aloe_oil",
        imageUrl: "/img/oils/aloe-oil.webp",
        price: 2.0,
      },
      {
        id: 2,
        name: "products.care_products.oils.argan_oil",
        imageUrl: "/img/oils/argan-oil.webp",
        price: 2.0,
      },
      {
        id: 3,
        name: "products.care_products.oils.avocado_oil",
        imageUrl: "/img/oils/avocado-oil.jpg",
        price: 2.0,
      },
      {
        id: 4,
        name: "products.care_products.oils.carrot_oil",
        imageUrl: "/img/oils/carrot-oil.png",
        price: 2.0,
      },
      {
        id: 5,
        name: "products.care_products.oils.castor_oil",
        imageUrl: "/img/oils/castor-oil.webp",
        price: 2.0,
      },
      {
        id: 6,
        name: "products.care_products.oils.chamomile_oil",
        imageUrl: "/img/oils/chamomile-oil.webp",
        price: 2.0,
      },
      {
        id: 7,
        name: "products.care_products.oils.clove_oil",
        imageUrl: "/img/oils/clove-oil.webp",
        price: 2.0,
      },
      {
        id: 8,
        name: "products.care_products.oils.coconut_oil",
        imageUrl: "/img/oils/coconut-oil.jpg",
        price: 2.0,
      },
      {
        id: 9,
        name: "products.care_products.oils.cod_liver_oil",
        imageUrl: "/img/oils/cod-liver-oil.webp",
        price: 2.0,
      },
      {
        id: 10,
        name: "products.care_products.oils.eucalyptus_oil",
        imageUrl: "/img/oils/eucalyptus-oil.webp",
        price: 2.0,
      },
      {
        id: 11,
        name: "products.care_products.oils.garlic_oil",
        imageUrl: "/img/oils/garlic-oil.webp",
        price: 2.0,
      },
      {
        id: 12,
        name: "products.care_products.oils.jasmine_oil",
        imageUrl: "/img/oils/jasmine-oil.webp",
        price: 2.0,
      },
      {
        id: 13,
        name: "products.care_products.oils.laurel_leaves_oil",
        imageUrl: "/img/oils/laurel-leaves-oil.webp",
        price: 2.0,
      },
      {
        id: 14,
        name: "products.care_products.oils.lavender_oil",
        imageUrl: "/img/oils/lavender-oil.webp",
        price: 2.0,
      },
      {
        id: 15,
        name: "products.care_products.oils.linseed_oil",
        imageUrl: "/img/oils/linseed-oil.webp",
        price: 2.0,
      },
      {
        id: 16,
        name: "products.care_products.oils.mint_oil",
        imageUrl: "/img/oils/mint-oil.webp",
        price: 2.0,
      },
      {
        id: 17,
        name: "products.care_products.oils.nettle_oil",
        imageUrl: "/img/oils/nettle-oil.webp",
        price: 2.0,
      },
      {
        id: 18,
        name: "products.care_products.oils.onion_oil",
        imageUrl: "/img/oils/onion-oil.webp",
        price: 2.0,
      },
      {
        id: 19,
        name: "products.care_products.oils.oregano_oil",
        imageUrl: "/img/oils/oregano-oil.webp",
        price: 2.0,
      },
      {
        id: 20,
        name: "products.care_products.oils.pomegranate_oil",
        imageUrl: "/img/oils/pomegranate-oil.webp",
        price: 2.0,
      },
      {
        id: 21,
        name: "products.care_products.oils.propolis_oil",
        imageUrl: "/img/oils/propolis-oil.webp",
        price: 2.0,
      },
      {
        id: 22,
        name: "products.care_products.oils.rose_oil",
        imageUrl: "/img/oils/rose-oil.webp",
        price: 2.0,
      },
      {
        id: 23,
        name: "products.care_products.oils.rosemary_oil",
        imageUrl: "/img/oils/rosemary-oil.webp",
        price: 2.0,
      },
      {
        id: 24,
        name: "products.care_products.oils.saffron_oil",
        imageUrl: "/img/oils/saffron-oil.webp",
        price: 2.0,
      },
      {
        id: 25,
        name: "products.care_products.oils.vitamin_e_oil",
        imageUrl: "/img/oils/vitamin-e-oil.webp",
        price: 2.0,
      },
    ],
  },
  skinproducts: {
    id: 7,
    title: "",
    routeName: "skinproducts",
    items: [
      {
        id: 1,
        name: "products.care_products.skin_products.african_black_soap",
        imageUrl: "/img/oils/Soap.jpg",
        price: 2.0,
      },
      {
        id: 2,
        name: "products.care_products.skin_products.blackseed_soap",
        imageUrl: "/img/oils/Soap2.png",
        price: 2.0,
      },
      {
        id: 14,
        name: "products.care_products.skin_products.black_seed_olive_cream",
        imageUrl: "/img/oils/black_seed_olive_cream.png",
        price: 4.0,
      },
      {
        id: 15,
        name: "products.care_products.skin_products.black_seed_face_cream",
        imageUrl: "/img/oils/black_seed_face_cream.png",
        price: 4.0,
      },
      {
        id: 6,
        name: "products.care_products.skin_products.coffe_cream",
        imageUrl: "/img/oils/Cream3.jpg",
        price: 8.0,
      },
      {
        id: 3,
        name: "products.care_products.skin_products.dudu_osun_soap",
        imageUrl: "/img/oils/Soap3.jpg",
        price: 3.5,
      }, 
      {
        id: 7,
        name: "products.care_products.skin_products.el_baraka_soap",
        imageUrl: "/img/oils/Soap4.jpg",
        price: 2.0,
      },
      {
        id: 8,
        name: "products.care_products.skin_products.el_baraka_7in1_soap",
        imageUrl: "/img/oils/Soap5.jpg",
        price: 2.0,
      },
      {
        id: 10,
        name: "products.care_products.skin_products.el_baraka_black_seed_soap",
        imageUrl: "/img/oils/Soap6.jpg",
        price: 2.0,
      },
      {
        id: 18,
        name: "products.care_products.skin_products.goat_soap",
        imageUrl: "/img/oils/goat_soap.webp",
        price: 2.0,
      },
      {
        id: 9,
        name: "products.care_products.skin_products.himomed_cream",
        imageUrl: "/img/oils/Cream4.jpg",
        price: 4.0,
      },
     
      {
        id: 16,
        name: "products.care_products.skin_products.lady_diana_black_seed_cream",
        imageUrl: "/img/oils/lady_diana_black_seed_cream.png",
        price: 4.0,
      },
      // {
      //   id: 11,
      //   name: "products.care_products.skin_products.acne_melasma",
      //   imageUrl: "/img/oils/acne-melasma.jpg",
      //   price: 0.0,
      // },
      {
        id: 12,
        name: "products.care_products.skin_products.scar_serum",
        imageUrl: "/img/oils/scar_serum.jpeg",
        price: 4.50,
      },
      {
        id: 4,
        name: "products.care_products.skin_products.snail_cream",
        imageUrl: "/img/oils/Cream.jpg",
        price: 5.0,
      },
      {
        id: 5,
        name: "products.care_products.skin_products.snake_cream",
        imageUrl: "/img/oils/Cream2.jpg",
        price: 5.0,
      },
     
      {
        id: 13,
        name: "products.care_products.skin_products.vitamin_e_shower",
        imageUrl: "/img/oils/vitamin-e-shower.jpeg",
        price: 5.0,
      },
      {
        id: 17,
        name: "products.care_products.skin_products.collagen_vitamin_e_cream",
        imageUrl: "/img/oils/collagen_vitamin_e.jpeg",
        price: 4.0,
      },
    ],
  },
  hairproducts: {
    id: 8,
    title: "",
    routeName: "hairproducts",
    items: [
      {
        id: 1,
        name: "products.care_products.hair_products.mane_n_tail_shampoo",
        imageUrl: "/img/oils/Shampoo.jpg",
        price: 10.0,
      },
      {
        id: 2,
        name: "products.care_products.hair_products.dexe_hair_shampoo",
        imageUrl: "/img/oils/Shampoo2.jpg",
        price: 6.0,
      },
      {
        id: 3,
        name: "products.care_products.hair_products.horse_tail_shampoo",
        imageUrl: "/img/oils/Shampoo3.jpg",
        price: 5.0,
      },
      {
        id: 4,
        name: "products.care_products.hair_products.hair_removal_spray_men",
        imageUrl: "/img/oils/Shampoo4.jpg",
        price: 10.0,
      },
      {
        id: 5,
        name: "products.care_products.hair_products.hair_removal_spray_women",
        imageUrl: "/img/oils/Shampoo5.jpg",
        price: 10.0,
      },
      {
        id: 6,
        name: "products.care_products.hair_products.vatika_henna_natural_black",
        imageUrl: "/img/oils/Hair.jpg",
        price: 5.0,
      },
      {
        id: 7,
        name: "products.care_products.hair_products.vatika_henna_natural_brown",
        imageUrl: "/img/oils/Hair2.jpg",
        price: 5.0,
      },
      {
        id: 8,
        name: "products.care_products.hair_products.vatika_henna_dark_brown",
        imageUrl: "/img/oils/Hair3.jpg",
        price: 5.0,
      },
    ],
  },
  healthyproducts: {
    id: 9,
    title: "",
    routeName: "healthyproducts",
    items: [
      {
        id: 1,
        name: "products.care_products.healthy_products.black_cummin_seed",
        imageUrl: "/img/oils/Balen.png",
        price: 10.0,
      },
      {
        id: 2,
        name: "products.care_products.healthy_products.omega_3_fish_oil",
        imageUrl: "/img/oils/Balen2.png",
        price: 12.0,
      },
      {
        id: 3,
        name: "products.care_products.healthy_products.red_ginseng",
        imageUrl: "/img/oils/Balen3.png",
        price: 12.0,
      },
      {
        id: 5,
        name: "products.care_products.healthy_products.gingko_biloba",
        imageUrl: "/img/oils/Balen5.png",
        price: 12.0,
      },
      {
        id: 6,
        name: "products.care_products.healthy_products.balen_forwomen",
        imageUrl: "/img/oils/Balen6.png",
        price: 15.0,
      },
      {
        id: 7,
        name: "products.care_products.healthy_products.balen_formen",
        imageUrl: "/img/oils/Balen7.png",
        price: 15.0,
      },
      {
        id: 8,
        name: "products.care_products.healthy_products.soft_capsule_supplement",
        imageUrl: "/img/oils/Balen8.png",
        price: 12.0,
      },
      {
        id: 9,
        name: "products.care_products.healthy_products.ome_pumpkin",
        imageUrl: "/img/oils/Balen9.png",
        price: 12.0,
      },
      {
        id: 10,
        name: "products.care_products.healthy_products.migrover",
        imageUrl: "/img/oils/Balen10.png",
        price: 12.0,
      },
      {
        id: 11,
        name: "products.care_products.healthy_products.hemout_plant",
        imageUrl: "/img/oils/Balen11.png",
        price: 12.0,
      },
      {
        id: 12,
        name: "products.care_products.healthy_products.spirulina",
        imageUrl: "/img/oils/Balen12.png",
        price: 12.0,
      },
      {
        id: 13,
        name: "products.care_products.healthy_products.gingko_biloba",
        imageUrl: "/img/oils/QY.jpeg",
        price: 15.0,
      },
      {
        id: 14,
        name: "products.care_products.healthy_products.zemzem",
        imageUrl: "/img/oils/zemzem.jpeg",
        price: 5.0,
      },
      {
        id: 15,
        name: "products.care_products.healthy_products.miswak_toothpaste",
        imageUrl: "/img/oils/miswak-toothpaste.jpeg",
        price: 2.50,
      },
      {
        id: 16,
        name: "products.care_products.healthy_products.miswak",
        imageUrl: "/img/oils/miswak.png",
        price: 1.50,
      },
      {
        id: 17,
        name: "products.care_products.healthy_products.miswak_and_toothpaste",
        imageUrl: "/img/oils/miswaktoothpaste.jpeg",
        price: 3.50,
      },
      {
        id: 18,
        name: "products.care_products.healthy_products.coffee_slimming",
        imageUrl: "/img/oils/coffee-slimming.jpeg",
        price: 8.0,
      },
      {
        id: 19,
        name: "products.care_products.healthy_products.axe_brand",
        imageUrl: "/img/oils/axe_brand.jpg",
        price: 1.50,
      },
    ],
  },
};

export default SHOP_DATA;
